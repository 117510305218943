export default [
  {
    label: 'Home',
    href: 'https://oredex.finance',
  },
  {
    label: 'Exchange',
    href: '/',
  },
  {
    label: 'BSC Staking',
    href: 'https://bsc.oredex.finance',
  },
  {
    label: 'Trade ORE Testnet',
    href: 'https://swap-testnet.oredex.finance',
  },
  // // {
  // //   label: "Audits",
  // //   icon: "ShibaNovaAuditIcon",
  // //   href: "https://docs.novadex.finance/shibanova-documentation/security/audits",
  // // },
  // {
  //   label: 'Trade Routes',
  //   href: 'https://novadex.finance/traderoutes',
  // },
  {
    label: 'Socials',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Telegram',
        icon: 'TelegramIcon',
        href: 'https://t.me/OreniumProtocol',
        external: true,
      },
      {
        label: 'Twitter',
        icon: 'TwitterIcon',
        href: 'https://twitter.com/OreniumProtocol',
        external: true,
      },
      {
        label: 'Medium',
        icon: 'MediumIcon',
        href: 'https://medium.com/@oreniumprotocol',
        external: true,
      },
      {
        label: 'Github',
        icon: 'GithubIcon',
        href: 'https://github.com/oreniumchain',
        external: true,
      },
      {
        label: 'Docs',
        icon: 'BookIcon',
        href: 'https://base.orenium.org',
        external: true,
      },
      // {
      //   label: "Discord",
      //   icon: "DiscordIcon",
      //   href: "https://discord.gg/nsxxXNjkqU",
      //   external: true,
      // },
    ],
  },
]
